@font-face {
  font-family: 'OpenDyslexic';
	font-style: normal;
	font-weight: normal;
	src: local('OpenDyslexic'), url('fonts/OpenDyslexic-Regular.woff') format('woff');;
} 

@font-face {
	font-family: 'OpenDyslexic';
	font-style: italic;
	font-weight: normal;
	src: local('OpenDyslexic-Italic'),url('fonts/OpenDyslexic-Italic.woff') format('woff');;
}

@font-face {
	font-family: 'OpenDyslexic';
	font-weight: bold;
	font-style: normal;
	src: local('OpenDyslexic-Bold'),url('fonts/OpenDyslexic-Bold.woff') format('woff');;
} 

@font-face {
	font-family: 'OpenDyslexic';
	font-weight: bold;
	font-style: italic;
	src: local('OpenDyslexic-Italic-Bold'),url('fonts/OpenDyslexic-BoldItalic.woff') format('woff');;
} 
